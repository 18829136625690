(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name home.controller:HomeCtrl
     *
     * @description
     *
     */
    angular.module('home').controller('HomeCtrl', HomeCtrl);

    function HomeCtrl(
        RCRUsers,
        RcrParent,
        Locations,
        $localStorage,
        $location,
        Aside,
        Messages,
        Downloads,
        $stateParams
    ) {
        var vm = this;
        vm.filter = {};
        vm.ctrlName = 'HomeCtrl';
        if (!RCRUsers.isAuthenticated()) {
            $location.path('/');
        }

        vm.currentUser = $localStorage.loggedInUser;
        vm.partials = [];
        vm.pendings = [];
        vm.rejects = [];
        vm.approveds = [];

        vm.csvColumnOrder =
            vm.currentUser.userType == 3
                ? Downloads.cphCsvColumnOrder
                : Downloads.csvColumnOrder;
        vm.csvHeader =
            vm.currentUser.userType == 3
                ? Downloads.cphCsvHeader
                : Downloads.csvHeader;

        vm.openMessages = function (reqId) {
            Aside.openAside(reqId);
        };

        vm.deleteRcr = function (parentid) {
            RcrParent.rcrDel({ id: parentid });
        };

        Locations.find().$promise.then(function (locations) {
            _.forEach(locations, function (location, key) {
                var x = moment().utc().unix();
                if (x > location.openDate && x < location.closeDate) {
                    location.isOpen = true;
                } else {
                    location.isOpen = false;
                }
                location.dateOpenFmt = moment
                    .utc(location.openDate * 1000)
                    .local()
                    .format('LT [on] LL');
                location.dateCloseFmt = moment
                    .utc(location.closeDate * 1000)
                    .local()
                    .format('LT [on] LL');
            });
            vm.locations = locations;
        });
        //get partials for current user
        /*RcrParent.find({
		"filter": {
			"include":[{"relation":"Rcr","scope": { "limit":1,"order": "id DESC"}},"Locations","Status"],
			"where": {
				"userId":$localStorage.loggedInUser.id,
				"statusId":1
			}
		}
	}).$promise.then(function(results){
		_.forEach(results,function(result, key){
			result.dateCreatedFmt = $filter('date')(result.dateCreated * 1000, "dd MMM yyyy");
			result.idsix = ("000000" + result.id).slice(-6);
		});
		vm.partials = results;
	});*/

        //var myModal = $modal({title: 'My Title', content: 'My Content', show: true});

        RcrParent.find({
            filter: {
                include: [
                    { relation: 'Rcr', scope: { limit: 1, order: 'id DESC' } },
                    'Locations',
                    'Status',
                ],
                where: {
                    userId: $localStorage.loggedInUser.id,
                },
            },
        }).$promise.then(function (results) {
            var x = moment().utc().unix();
            _.forEach(results, function (result, key) {
                Messages.getUnreadMessageCount(
                    {
                        rcrParentId: result.id,
                        userId: $localStorage.loggedInUser.id,
                    },
                    function (messageCount) {
                        result.dateCreatedFmt = moment
                            .utc(result.dateCreated * 1000)
                            .local()
                            .format('DD MMM YY');
                        result.dateSubmittedFmt = moment
                            .utc(result.dateSubmitted * 1000)
                            .local()
                            .format('DD MMM YY');
                        if (result.dateApproved) {
                            result.dateApprovedFmt = moment
                                .utc(result.dateApproved * 1000)
                                .local()
                                .format('DD MMM YY');
                        }
                        result.idsix = ('000000' + result.id).slice(-6);
                        result.unreadMessageCount =
                            messageCount[0]['messageCount'];

                        if (vm.currentUser.userType == 1) {
                            switch (result.statusId) {
                                case 1:
                                    if (
                                        x < result.Locations.openDate ||
                                        x > result.Locations.closeDate
                                    ) {
                                        result.viewOnly = true;
                                    }
                                    vm.partials.push(result);
                                    break;
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                    vm.pendings.push(result);
                                    break;
                                case 6:
                                    vm.rejects.push(result);
                                    break;
                                case 7:
                                    vm.approveds.push(result);
                                    break;
                                default:
                            }
                        } else if (result.statusId == 1) {
                            vm.partials.push(result);
                        }
                    }
                );
            });
            if ($stateParams.view == 'requests') {
                jQuery('html, body').animate(
                    {
                        scrollTop: 400,
                    },
                    1000
                );
            }
        });

        //if user is Brighton admin
        if (vm.currentUser.userType == 2) {
            //get sumbits
            RcrParent.find({
                filter: {
                    include: [
                        {
                            relation: 'Rcr',
                            scope: { limit: 1, order: 'id DESC' },
                        },
                        'Locations',
                        'Status',
                    ],
                    where: {
                        and: [
                            { locationId: 1 },
                            {
                                or: [
                                    { statusId: 2 },
                                    { statusId: 3 },
                                    { statusId: 4 },
                                    { statusId: 5 },
                                    { statusId: 6 },
                                    { statusId: 7 },
                                ],
                            },
                        ],
                    },
                },
            }).$promise.then(function (results) {
                _.forEach(results, function (result, key) {
                    Messages.getUnreadMessageCount(
                        {
                            rcrParentId: result.id,
                            userId: $localStorage.loggedInUser.id,
                        },
                        function (messageCount) {
                            result.dateSubmittedFmt = moment
                                .utc(result.dateSubmitted * 1000)
                                .local()
                                .format('DD MMM YY');
                            if (result.dateApproved) {
                                result.dateApprovedFmt = moment
                                    .utc(result.dateApproved * 1000)
                                    .local()
                                    .format('DD MMM YY');
                            }
                            result.idsix = ('000000' + result.id).slice(-6);
                            result.unreadMessageCount =
                                messageCount[0]['messageCount'];
                        }
                    );
                });
                vm.submits = results;
            });
        }

        if (vm.currentUser.userType == 3) {
            //get sumbits
            RcrParent.find({
                filter: {
                    include: [
                        {
                            relation: 'Rcr',
                            scope: { limit: 1, order: 'id DESC' },
                        },
                        'Locations',
                        'Status',
                    ],
                    where: {
                        and: [
                            { locationId: 2 },
                            {
                                or: [
                                    { statusId: 2 },
                                    { statusId: 3 },
                                    { statusId: 4 },
                                    { statusId: 5 },
                                    { statusId: 6 },
                                    { statusId: 7 },
                                ],
                            },
                        ],
                    },
                },
            }).$promise.then(function (results) {
                _.forEach(results, function (result, key) {
                    Messages.getUnreadMessageCount(
                        {
                            rcrParentId: result.id,
                            userId: $localStorage.loggedInUser.id,
                        },
                        function (messageCount) {
                            result.dateSubmittedFmt = moment
                                .utc(result.dateSubmitted * 1000)
                                .local()
                                .format('DD MMM YY');
                            if (result.dateApproved) {
                                result.dateApprovedFmt = moment
                                    .utc(result.dateApproved * 1000)
                                    .local()
                                    .format('DD MMM YY');
                            }
                            result.idsix = ('000000' + result.id).slice(-6);
                            result.unreadMessageCount =
                                messageCount[0]['messageCount'];
                        }
                    );
                });
                vm.submits = results;
            });
        }

        vm.downloadPDF = function (locationId) {
            var link = document.createElement('a');
            link.download = 'EXCEPTIONAL RULE CHANGE REQUEST FORM';
            if (locationId === 1) {
                link.href = 'images/BIN-NOPR-MISF-EN-1810-V20-XXXX-0013986.pdf';
            } else {
                link.href = 'images/BIN-NOPR-MISF-EN-1810-V20-XXXX-0013989.pdf';
            }
            link.click();
        };

        vm.editRequest = function (rcrId) {
            $localStorage.rcrToEdit = rcrId;
            $location.path('/form');
        };

        vm.createRequest = function (locId) {
            $localStorage.rcrToEdit = null;
            $localStorage.requestLocation = locId;
            $location.path('/form');
        };

        vm.downloadCsv = function (rcrArray) {
            return Downloads.rcrBuild(rcrArray);
        };

        vm.isBrighton = function (location) {
            return location.id == 1;
        };
    }
})();
